// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-corporate-gifting-js": () => import("./../../../src/pages/corporate-gifting.js" /* webpackChunkName: "component---src-pages-corporate-gifting-js" */),
  "component---src-pages-custom-cakes-js": () => import("./../../../src/pages/custom-cakes.js" /* webpackChunkName: "component---src-pages-custom-cakes-js" */),
  "component---src-pages-gifting-js": () => import("./../../../src/pages/gifting.js" /* webpackChunkName: "component---src-pages-gifting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-nomination-js": () => import("./../../../src/pages/nomination.js" /* webpackChunkName: "component---src-pages-nomination-js" */),
  "component---src-pages-online-order-js": () => import("./../../../src/pages/online-order.js" /* webpackChunkName: "component---src-pages-online-order-js" */),
  "component---src-pages-order-cake-online-js": () => import("./../../../src/pages/order-cake-online.js" /* webpackChunkName: "component---src-pages-order-cake-online-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-why-deliciae-js": () => import("./../../../src/pages/why-deliciae.js" /* webpackChunkName: "component---src-pages-why-deliciae-js" */),
  "component---src-templates-cake-shop-js": () => import("./../../../src/templates/cake-shop.js" /* webpackChunkName: "component---src-templates-cake-shop-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-post-page-js": () => import("./../../../src/templates/post-page.js" /* webpackChunkName: "component---src-templates-post-page-js" */)
}

